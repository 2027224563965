<template>
  <div
    class="FilterOtherCountries has-background-grey-lighter is-full-height p-a-m is-flex is-aligned-bottom is-aligned-end"
    data-testid="FilterOtherCountries"
  >
    <grouped-dropdown-list
      :elements="countriesList"
      :handler="handleElementClick"
      filter-by="name"
      label-key="name"
      menu-classes="has-max-height"
      data-testid="FilterOtherCountries__dropdown-menu"
    >
      <div slot="trigger" class="has-text-weight-bold is-size-5" data-testid="FilterOtherCountries__Trigger">
        {{ $t('components.filter_locations.other_countries') }}
        <v-icon type="caret-down" />
      </div>
    </grouped-dropdown-list>
  </div>
</template>

<script>
import _groupBy from 'lodash/groupBy'
import GroupedDropdownList from '@/components/core/GroupedDropdownList'

export default {
  components: {
    GroupedDropdownList
  },

  props: {
    countries: {
      type: Array,
      required: true
    }
  },

  computed: {
    countriesList () {
      return _groupBy(this.countries, country => {
        return country.name.slice(0, 1)
      })
    }
  },
  methods: {
    handleElementClick (selected) {
      this.$emit('change', selected)
    }
  }
}
</script>
