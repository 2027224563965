<template>
  <div
    v-clickoutside="handleClickOutside"
    :class="{'is-active': isVisible, 'has-checkboxes': hasCheckboxes, 'is-selected': isSelected }"
    class="dropdown DropdownList"
  >
    <div
      class="dropdown-trigger"
      @click="isVisible = !isVisible"
    >
      <slot
        name="trigger"
      />
    </div>
    <div :class="menuClasses" class="dropdown-menu" role="menu">
      <div :class="['dropdown-content', {'is-active': isVisible, 'not-active': !isVisible}]">
        <div v-if="filterBy" class="p-a-s">
          <input
            v-model="input"
            :placeholder="filterPlaceholder"
            type="text"
            class="input"
          >
        </div>
        <div
          v-if="$isEmpty(filteredElements)"
          data-testid="DropdownList__no-items"
          class="dropdown-item has-text-grey-light"
        >
          {{ $t('components.dropdown_list.no_items_in_list') }}
        </div>
        <template v-else>
          <div
            v-for="(group, key) in filteredElements"
            :key="key"
            :class="['dropdown-item']"
          >
            <p class="group-header">
              {{ key }}
            </p>
            <a
              v-for="element in group"
              :key="element[keyBy]"
              :class="['dropdown-item', {'is-selected': isElementSelected(element)}]"
              :data-testid="element.name"
              @click="toggleSelected(valueKey ? element[valueKey] : element)"
            >
              <slot :element="element">{{ labelKey ? element[labelKey]: element }}</slot>
              <span :class="['hf', {'hf-check' : isElementSelected(element)}]" />
            </a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownList from '@hypefactors/shared/js/components/core/DropdownList'

export default {
  extends: DropDownList,
  props: {
    elements: {
      type: Object,
      required: true
    },
    selectedElements: {
      type: Array,
      default: () => ([])
    },
    labelKey: {
      type: String,
      default: ''
    }
  },
  computed: {
    filteredElements () {
      if (this.filterBy === '') {
        return this.elements
      }

      const normalizedSearch = this.input.toLowerCase().trim()
      let elems = this.elements
      if (!Array.isArray(this.elements)) {
        // return a new array from the groupBy objects keys
        // results in a 2d array being returned
        // nested members must be array types
        elems = Object.entries(this.elements)
      }

      const filtered = elems
        .map(([groupName, groupValues]) => {
          return [
            groupName,
            groupValues.filter(v => {
              return v[this.filterBy].toLowerCase().includes(normalizedSearch)
            })]
        })
        .filter(([groupName, groupValues]) => {
          return groupValues.length
        })

      return Object.fromEntries(filtered)
    }
  },
  methods: {
    handleClickOutside () {
      this.isVisible = false
      this.input = ''
    }
  }
}
</script>
