<template>
  <page-section class="Discover" hide-title>
    <template #above-title>
      <filter-locations />

      <dashboard-filters-v2 class="m-b-m">
        <template #primary>
          <search-input />
        </template>

        <template #tertiary>
          <dashboard-filters-v2-item>
            <filter-category-picker />
          </dashboard-filters-v2-item>

          <dashboard-filters-v2-item>
            <filter-sort-by />
          </dashboard-filters-v2-item>
        </template>
      </dashboard-filters-v2>
    </template>

    <load-more
      ref="profiles"
      :endpoint="profilesEndpoint"
      :placeholder-items="2"
      width="column is-6 z-index-auto"
      placeholder="MediaProfilePlaceholder"
    >
      <template #item="{ item }">
        <media-profile-card
          :profile="item"
          @relation-added="$refs.profiles.removeItem"
        />
      </template>
    </load-more>
  </page-section>
</template>

<script>
import { mapGetters } from 'vuex'

import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'

import FilterLocations from '@/components/connect/_filters/FilterLocations'
import SearchInput from '@/components/connect/_filters/SearchInput'
import FilterCategoryPicker from '@/components/connect/_filters/CategoriesFilter'
import FilterSortBy from '@/components/connect/_filters/SortBy'
import MediaProfileCard from '@/components/connect/discover/Card'

export default {
  components: {
    FilterLocations,
    SearchInput,
    FilterCategoryPicker,
    FilterSortBy,
    MediaProfileCard
  },

  computed: {
    ...mapGetters(['getFilter']),

    profilesEndpoint () {
      return buildUrlWithParams('/connect/media-profiles', {
        brand: this.activeBrandId,
        limit: 20,
        search: this.getFilter('searchQuery'),
        sort: this.getFilter('sortBy'),
        categories: this.getFilter('categories'),
        countries: this.getFilter('country')
      })
    }
  }
}
</script>
