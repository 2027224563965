<template>
  <div class="FilterLocationsItem p-a-m is-cover-image is-column is-relative" :style="background" @click="addFilter">
    <v-button
      class="m-l-m is-transparent is-large"
      data-testid="FilterLocation__highlighted__backButton"
      @click.stop="removeFilter"
    >
      <round-icon bg-color="white" type="angle-left" />
      <span>{{ $t('general.back') }}</span>
    </v-button>

    <div class="Filter-locations__title m-t-a has-text-white has-text-weight-bold is-size-4 has-text-right">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    code: { required: true, type: String },
    name: { required: true, type: String }
  },

  computed: {
    background () {
      const countryPath = this.$asset('/images/countries')

      return `background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(255, 255, 255, 0) 100%), url("${countryPath}/${this.code}/Banner@2x.jpg"), url("${countryPath}/Fallback/Banner@2x.jpg");`
    }
  },

  methods: {
    addFilter () {
      this.$emit('selected', {
        name: this.name, iso_alpha2: this.code
      })
    },

    removeFilter () {
      this.$emit('deselected')
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.FilterLocationsItem {
  overflow: hidden;
  height: 140px;

  @include tablet {
    height: 300px;
  }

  button {
    position: absolute;
    padding: 0;
    margin: 0;
    font-size: $hf__font-label;
    color: $white !important;
    text-transform: capitalize;
    background: none;
    border: 0;
    transform: translateY(-100px);
    @include transit(transform, 0.3s);
  }

  &.is-active {
    button {
      transform: translateY(0);
    }
  }
}
</style>
