<template>
  <dashboard-filters-category-picker :choices="categories" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import DashboardFiltersCategoryPicker from '@hypefactors/shared/js/components/filters/DashboardFiltersCategoryPicker'

export default {
  name: 'CategoriesFilter',

  components: {
    DashboardFiltersCategoryPicker
  },

  computed: {
    ...mapGetters('connect/categories', {
      'categories': 'allTranslated'
    })
  },

  mounted () {
    if (!this.categories || this.categories.length === 0) {
      this.fetchCategories()
    }
  },

  methods: {
    ...mapActions('connect/categories', {
      'fetchCategories': 'fetch'
    })
  }
}
</script>
