<template>
  <dropdown-list
    :elements="choices"
    :selected-elements="selectedCategories"
    :handler="toggleSelectedCategories"
    filter-by="name"
    order-by="name"
    label-key="name"
    value-key="slug"
    is-active-prop="slug"
    class="picker"
    has-checkboxes
  >
    <template #trigger>
      <dashboard-filters-icon
        :label="$t('general.categories')"
        class="DashboardFiltersV2-DropdownTrigger"
        icon="book"
      />
    </template>

    <template #default="{ element }">
      <span class="is-uppercase">
        {{ element.name }}
      </span>
    </template>
  </dropdown-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

/**
 * @module DashboardFiltersCategoryPicker
 */
export default {
  name: 'DashboardFiltersCategoryPicker',

  props: {
    choices: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters('globalFilters', [
      'selectedCategories'
    ])
  },

  methods: {
    ...mapActions('globalFilters', [
      'toggleSelectedCategories'
    ])
  }
}
</script>
