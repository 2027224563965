<template>
  <div class="columns is-variable-grid is-1">
    <div
      v-if="highlighted"
      class="column is-12"
      data-testid="FilterOtherCountries__highlighted"
    >
      <filter-locations-item
        :code="highlighted.iso_alpha2"
        :name="highlighted.name"
        class="is-active"
        data-testid="filter-counteries"
        @selected="selected"
        @deselected="deselected"
      />
    </div>

    <template v-else>
      <div
        class="column is-5 is-cursor-pointer"
        data-testid="FilterOtherCountries__primary"
      >
        <filter-locations-item
          :code="currentLocation.iso_alpha2"
          :name="currentLocation.name"
          @selected="selected"
          @deselected="deselected"
        />
      </div>

      <div
        v-for="location in neighbours"
        :key="location.id"
        class="column is-cursor-pointer"
      >
        <filter-locations-item
          :code="location.iso_alpha2"
          :name="location.name"
          class="is-small"
          @selected="selected"
          @deselected="deselected"
        />
      </div>

      <div class="column">
        <filter-other-countries
          :countries="countries"
          @change="selectCountryFromList"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FilterLocationsItem from './FilterLocationsItem'
import FilterOtherCountries from './FilterOtherCountries'

export default {
  components: {
    FilterLocationsItem,
    FilterOtherCountries
  },

  data () {
    return {
      highlighted: null,
      otherCountriesModal: false
    }
  },

  computed: {
    ...mapGetters(['currentLocation', 'neighbouringLocations', 'countries', 'getFilter']),

    neighbours () {
      const countries = this.neighbouringLocations.length ? this.neighbouringLocations : this.countries.slice(1)

      return countries.slice(0, 3)
    }
  },

  mounted () {
    const selectedCountry = this.getFilter('country')

    if (!this.highlighted) {
      if (selectedCountry) {
        const country = this.countries.find(c => c.iso_alpha2 === selectedCountry[0])

        this.highlighted = country
      } else {
        this.selected(this.currentLocation)
      }
    }
  },

  methods: {
    ...mapActions(['removeFilter', 'setFilter']),

    selected (location) {
      this.highlighted = location

      this.setFilter({
        name: 'country', value: [location.iso_alpha2]
      })
    },

    deselected () {
      this.highlighted = null

      this.setFilter({
        name: 'country', value: null
      })
    },

    selectCountryFromList (country) {
      this.otherCountriesModal = false

      this.selected({
        name: country.name, iso_alpha2: country.iso_alpha2
      })
    }
  }
}
</script>
